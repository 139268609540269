<template>
  <div
    v-if="postId"
    class="audio-wrapper"
    data-testid="audio-wrapper"
  >
    <AudioPlayer
      v-if="state.showPlayer"
      :source="source"
      :autoplay="autoplay"
      v-bind="$attrs"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue'
import AudioPlayer from './AudioPlayer.vue'

const props = withDefaults(
  defineProps<{
    source: string
    postId?: number
    autoplay?: boolean
  }>(),
  {
    source: '',
    postId: 0,
    autoplay: false,
  },
)

const emit = defineEmits<{
  (event: 'fetchData', postId: number): void
}>()

const clicked = ref(false)

const showAudio = computed(() => {
  if (props.source && clicked.value) {
    return true
  }

  return false
})

const state = reactive({
  showPlayer: showAudio,
})

onMounted(() => {
  if (!props.source) {
    emit('fetchData', props.postId)
  }

  clicked.value = true
})
</script>

<style>
.audio-wrapper {
  @apply min-h-24 h-auto w-full py-px flex items-center;
}
</style>
