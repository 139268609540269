<template>
  <div class="audio-container" data-testid="audio-player">
    <audio
      ref="player"
      :autoplay="autoplay"
      class="audio-player"
      @loadedmetadata="onAudioLoaded"
      @play="onAudioPlay"
      @pause="onAudioPause"
      @ended="onAudioEnd"
      @timeupdate="onAudioTimeUpdate"
    >
      <source
        v-if="source"
        :src="source"
        type="audio/mpeg"
      />
      Your browser does not support the audio element.
    </audio>
    <div class="audio-mask" :class="[source ? '' : 'audio-mask--inactive']">
      <div class="audio-mask__wrapper">
        <div class="audio-mask__progress">
          <RangeSlider
            additional-class="audio-mask__progress-slider"
            :max="playerState.totalTimeRange"
            :current="playerState.currentTimeRange"
            @set="setProgress"
          />
        </div>
        <div class="audio-mask__bottom">
          <div class="audio-mask__controls">
            <div
              title="Play"
              class="audio-mask__controls-button"
              @click="pause()"
            >
              <PlayIcon
                class="icon-play"
                :class="[playerState.paused ? '' : 'hidden']"
              />
              <PauseIcon
                class="icon-pause"
                :class="[playerState.paused ? 'hidden' : '']"
              />
            </div>
          </div>

          <div class="audio-mask__duration">
            <span class="audio-mask__progress--current">
              {{ playerState.currentTime }}
            </span>
            <span class="audio-mask__progress--delimiter">/</span>
            <span class="audio-mask__progress--max">
              {{ playerState.totalTime }}
            </span>
          </div>

          <div class="audio-mask__volume">
            <RangeSlider
              additional-class="audio-mask__volume-slider"
              :max="1"
              :step="0.1"
              :current="playerState.volume"
              @set="setVolume"
            />

            <div class="audio-mask__volume-button" @click="toggleMute">
              <SoundIcon
                class="icon-unmuted"
                :class="[playerState.muted ? 'hidden' : '']"
              />
              <MuteIcon
                class="icon-muted"
                :class="[playerState.muted ? '' : 'hidden']"
              />
            </div>
          </div>

          <div class="audio-mask__playback">
            <div class="audio-mask__playback-wrapper" @click="toggleDropdown">
              <span>{{ activePlayback.pace }}</span>
              <ArrowUpIcon
                :class="{
                  'audio-mask__playback-rotate': showPlaybackDropdown,
                }"
              />
            </div>
            <ul
              v-if="showPlaybackDropdown"
              aria-expanded="false"
              role="list"
              class="audio-mask__playback-list"
            >
              <li
                v-for="(item, index) in playbackOptions"
                :key="index"
                class="audio-mask__playback-option"
                :class="{
                  selected: item.value === playerState.playback,
                }"
                :data-playback-value="item.value"
                @click="setPlayback(item)"
              >
                {{ item.pace }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import ArrowUpIcon from '../../assets/svg/ArrowUp.svg'
import MuteIcon from '../../assets/svg/Mute.svg'
import PauseIcon from '../../assets/svg/Pause.svg'
import PlayIcon from '../../assets/svg/Play.svg'
import SoundIcon from '../../assets/svg/Sound.svg'
import RangeSlider from './RangeSlider.vue'

const props = withDefaults(
  defineProps<{
    source?: string
    autoplay?: boolean
  }>(),
  {
    source: '',
    autoplay: false,
  },
)

const emits = defineEmits<{
  (event: 'fetchData', data: boolean): void
  (event: 'loadPlayer', data: { max: string, muted: boolean }): void
  (event: 'play', data: boolean): void
  (event: 'pause', data: { progress: number }): void
}>()

const player = ref(null as unknown as HTMLMediaElement)
const showPlaybackDropdown = ref(false)
const playbackOptions = ref([
  { pace: 'langsam', value: 0.9 },
  { pace: 'normal', value: 1.1 },
  { pace: 'schnell', value: 1.3 },
])
const previousVolume = ref(1)

// initial player state values
const playerState = reactive({
  currentTime: '00:00',
  currentTimeRange: 0,
  totalTime: '00:00',
  totalTimeRange: 0,
  volume: 1,
  muted: false,
  paused: props.source ? !props.autoplay : true,
  playback: 1.1,
})

const activePlayback = computed(() => {
  return (
    playbackOptions.value.find(
      (item: any) => item.value === playerState.playback,
    ) ?? { pace: 'normal' }
  )
})

// Player event loadedmetadata: fired when the player's metadata have been loaded.
function onAudioLoaded(event: Event) {
  if (!player.value) { return }

  const target = event.target as HTMLMediaElement
  player.value = target
  playerState.totalTime = formatTime(player.value.duration)
  playerState.totalTimeRange = player.value.duration * 100
  player.value.defaultPlaybackRate = playerState.playback
  player.value.playbackRate = playerState.playback

  emits('loadPlayer', {
    max: playerState.totalTime,
    muted: playerState.muted,
  })

  // Stop player before unload to emit final pause event.
  window.onbeforeunload = () => {
    if (!playerState.paused) {
      player.value.pause()
    }
  }
}

// Player event play(): fired when the player was started.
function onAudioPlay() {
  if (!player.value) { return }

  playerState.paused = false
  emits('play', true)
}

// Player event pause(): fired when the player was stopped or ended.
function onAudioPause() {
  if (!player.value) { return }

  playerState.paused = true
  emits('pause', {
    progress: Math.round(
      (player.value.currentTime / player.value.duration) * 100,
    ),
  })
}

// Player event: fired when the player ended.
function onAudioEnd() {
  if (!player.value) { return }

  playerState.paused = true
  player.value.currentTime = 0
}

// Player event timeupdate(): fired when the time indicated by the currentTime attribute was updated.
function onAudioTimeUpdate() {
  if (!player.value) { return }

  playerState.currentTime = formatTime(player.value.currentTime)
  playerState.currentTimeRange = player.value.currentTime * 100
}

// Click event on mask's play button.
function pause() {
  if (!player.value) { return }

  if (props.source) {
    playerState.paused = !playerState.paused
    playerState.paused ? player.value.pause() : player.value.play()
  }
  else {
    emits('fetchData', true)
  }
}

function toggleMute() {
  if (!player.value) { return }

  playerState.muted = !playerState.muted

  if (playerState.muted) {
    previousVolume.value = player.value.volume
  }

  player.value.volume = playerState.muted ? 0 : previousVolume.value ?? 1
  playerState.volume = player.value.volume
}

// When progress value changed by hand pass new value to rangeSlider component.
function setProgress(i: number | string) {
  if (!player.value) { return }

  player.value.currentTime = +i / 100
  playerState.currentTimeRange = Number(i)
}

// When volume value changed by hand pass new value to rangeSlider component.
function setVolume(i: number | string) {
  if (!player.value) { return }

  player.value.volume = +i
  playerState.volume = Number(i)
}

// Change playback input.
function setPlayback(item: any) {
  if (!player.value) { return }

  playerState.playback = item.value
  player.value.playbackRate = playerState.playback

  showPlaybackDropdown.value = false
}

function toggleDropdown() {
  showPlaybackDropdown.value = !showPlaybackDropdown.value
}

// Helper function: nice format for time display.
function formatTime(val: number) {
  const hhmmss = new Date(val * 1000).toISOString().substr(11, 8)
  return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss
}
</script>

<style>
.audio-player {
  @apply hidden;
}

.audio-container,
.audio-player,
.audio-mask {
  @apply relative w-full;
}

.audio-mask {
  @apply bg-gray-100 p-2.5 flex flex-nowrap justify-between items-stretch rounded-lg text-sm leading-4;

  font-family: Roboto-Regular, Roboto, sans-serif;

  &__controls {
    @apply w-10 h-10;

    &-button {
      @apply rounded-full p-2 w-full h-full cursor-pointer;

      .icon-play,
      .icon-pause {
        @apply w-full h-full;
      }

      &:hover {
        @apply bg-gray-200;
      }
    }
  }

  &__wrapper {
    @apply flex flex-col w-full;
  }

  &__progress {
    @apply inline-flex flex-grow items-center h-8 mx-1 gap-1;

    &-slider {
      @apply flex-grow h-full ml-1 mt-2 mb-3;

      .range-slider__track {
        @apply h-1/6;
      }

      .range-slider__thumb {
        @apply top-[34%] w-3 h-[38%];
      }
    }
  }

  &__bottom {
    @apply flex w-full;
  }

  &__duration {
    @apply flex items-center ml-2;
  }

  &__volume {
    @apply flex justify-end mx-1 h-10 min-w-10 flex-grow;

    &:hover {
      .audio-mask__volume-slider {
        @apply block w-16;
      }
    }

    &-slider {
      @apply hidden mr-1;

      .range-slider__thumb {
        @apply h-[28%] top-[36%];
      }
    }

    &-button {
      @apply rounded-full p-2 w-auto h-10 cursor-pointer;

      .icon-unmuted {
        @apply -ml-[1px];
      }

      &:hover {
        @apply bg-gray-200;
      }
    }
  }

  &__playback {
    @apply flex items-center lowercase;

    &-wrapper {
      @apply flex items-center gap-2 cursor-pointer font-bold;
    }

    &-rotate {
      @apply rotate-180;
    }

    &-list {
      @apply absolute right-0 shadow-dropdown flex flex-col gap-4 p-6 bg-white top-[90%] rounded-lg z-10;
    }

    &-option {
      @apply cursor-pointer font-bold hover:text-gray-400;

      &.selected {
        @apply text-gray-400;
      }
    }
  }

  @media (min-width: 50rem) {
    font-size: 1rem;

    &__progress {
      &--delimiter,
      &--max {
        @apply block;
      }
    }
  }
}

.audio-mask--inactive {
  .audio-mask__controls--button,
  .audio-mask__volume-button,
  .audio-mask__playback-rate + label {
    @apply text-gray-400;
  }
}
</style>
