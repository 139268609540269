<template>
  <div>
    <LazyArticleExternalArticle
      v-if="postData.external.provider"
      :post="postData"
      :shorten-content="!showContent"
      :interaction-stats="interactionStats"
      @change-gdpr="changeGdpr"
      @fetch-data="fetchAudioData"
      @interaction-clicked="onInteractionClicked"
    />
    <LazyNPArticle
      v-else
      :post="postData"
      :shorten-content="!showContent"
      :interaction-stats="interactionStats"
      :gdpr-settings="gdprSettings"
      @change-gdpr="changeGdpr"
      @fetch-data="fetchAudioData"
      @interaction-clicked="onInteractionClicked"
    />

    <ClientOnly>
      <LazyArticleGiftArticle
        v-if="postData.isVplus && showGiftArticle"
        :post="postData"
        @close="onCloseGiftArticle"
      />
      <LazyArticleGiftValidateGiftToken
        v-if="postData.isVplus"
        :post="postData"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  post: Post
}>()

const postData = ref<Post>(props.post)
const showGiftArticle = computed(() => useGiftArticle().isVisible())

const gdprSettings = useGdpr().getSettings()

const showContent = computed(() => useAuth().getUser()?.piano?.abo?.hasPlusAccess ?? false)

const interactionStats = computed(() => {
  const stats: InteractionItem[] = [
    {
      type: 'comment-count',
      title: postData?.value.commentCount || '0',
      disabled: false,
    },
  ]

  if (postData.value.audio?.id) {
    stats.push({
      type: 'text-to-speech',
      title: 'Vorlesen',
      disabled: (!showContent.value && postData.value.isVplus) ?? false,
    })
  }

  if (postData.value.isVplus) {
    stats.push({
      type: 'gift',
      title: 'Verschenken',
      disabled: !showContent.value,
    })
  }

  return stats
})

function changeGdpr(provider: string) {
  useGdpr().updateEmbedProvider(provider)
  usePureSubscription().saveConsents()
}

function onInteractionClicked(item: InteractionItem) {
  if (item.type === 'gift') {
    useGiftArticle().showModal()
  }
}

function onCloseGiftArticle() {
  useGiftArticle().closeModal()
}

async function fetchAudioData(postId: number) {
  const { data: audio, error } = await useFetch(`/wp-json/tts/v1/speech_service?p=${postId}`)

  if (!error.value) {
    postData.value.audio.source = audio.value.file
    postData.value.audio.autoplay = true
  }
}
</script>
