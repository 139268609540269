<template>
  <button
    class="interaction-button"
    :disabled="interaction.disabled"
    data-allow-mismatch="attribute"
  >
    <component
      :is="iconComponent"
      v-if="interaction.type"
      :key="interaction.type"
    />
    <span v-if="interaction.title">
      {{ interaction.title }}
    </span>
  </button>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

const props = defineProps<{
  interaction: InteractionItem
}>()

const interactionIcon: { [key: string]: string } = {
  'read-it-later': 'ReadItLater',
  'share': 'Share',
  'gift': 'Gift',
  'text-to-speech': 'TextToSpeech',
  'comment-count': 'CommentCount',
  'home': 'Home',
  'tipp': 'Tipp',
}

const iconComponent = interactionIcon[props.interaction.type] ? defineAsyncComponent(() => import(`../../assets/svg/${interactionIcon[props.interaction.type]}.svg`)) : null
</script>

<style>
.interaction-button {
  @apply flex flex-col items-center justify-end w-auto h-auto gap-y-1;

  &[disabled] {
    @apply cursor-not-allowed;

    svg,
    span {
      @apply opacity-50;
    }
  }

  span {
    @apply text-gray-600;
  }
}
</style>
