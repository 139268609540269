export function useGiftArticle() {
  const isModalVisible = useState<boolean>(() => false)

  return {
    status: async (h: string, t: string, identifier: number): Promise<GiftArticleStatusResult> => {
      const res = ref<GiftArticleStatusResult>({} as GiftArticleStatusResult)

      await useFetch(`/wp-json/russmedia-article-sharing/v1/status`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        query: {
          h,
          t,
          identifier,
        },
        onResponseError({ response }) {
          res.value = response._data
          return response
        },
        onResponse({ response }) {
          res.value = response._data
          return response
        },
      })

      return res.value
    },
    share: async (h: string, t: string, identifier: number): Promise<GiftArticleShareResult> => {
      const res = ref<GiftArticleShareResult>({} as GiftArticleShareResult)
      await useFetch('/wp-json/russmedia-article-sharing/v1/store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          h,
          t,
          identifier,
        }),
        onResponseError({ response }) {
          res.value = response._data
          return response
        },
        onResponse({ response }) {
          res.value = response._data
          return response
        },
      })

      return res.value
    },
    validate: async (identifier: number, token: string): Promise<GiftArticleValidateResult> => {
      const res = ref<GiftArticleValidateResult>({} as GiftArticleValidateResult)
      await useFetch('/wp-json/russmedia-article-sharing/v1/validate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifier,
          token,
        }),
        onResponseError({ response }) {
          res.value = response._data
          return response
        },
        onResponse({ response }) {
          res.value = response._data
          return response
        },
      })

      return res.value
    },
    showModal: () => {
      isModalVisible.value = true
    },
    closeModal: () => {
      isModalVisible.value = false
    },
    isVisible: () => isModalVisible.value,
  }
}
