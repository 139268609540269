<template>
  <div class="mx-auto my-8 md:px-8">
    <template v-if="post">
      <Article :post="post" />

      <div class="flex flex-col max-w-screen-sm py-4 mx-auto gap-y-4">
        <ClientOnly>
          <CommonInteractionBar
            :stats="bottomInteractionStats"
            class="py-4 rounded-lg bg-main-200"
            @interaction-clicked="onInteractionClicked"
          />
          <LazyArticleSocialBookmarksModal
            v-if="showSocialBookmarks"
            :url="post.link"
            :text="post?.title ?? ''"
            @close="closeSocialBookmarks"
            @button-clicked="closeSocialBookmarks"
          />
          <template #fallback>
            <CommonInteractionBar
              class="py-4 rounded-lg bg-main-200"
              :stats="bottomInteractionStats.map((stat: InteractionItem) => ({ ...stat, disabled: true }))"
              @interaction-clicked="onInteractionClicked"
            />
          </template>
        </ClientOnly>

        <LazyArticleBottomReaderSuggestionsWrapper v-if="showReaderSuggestion" :provider="post?.external?.provider ? post.external.provider : 'vol'" />

        <LazyArticleBottomReactionsWrapper :post="post" />

        <ClientOnly>
          <LazyArticleBottomTopicCloudWrapper :post="post" />
        </ClientOnly>

        <AdSlot ad-slot="outbrain_after_article" />

        <ClientOnly>
          <Forum
            v-if="post"
            :post="post"
          />
        </ClientOnly>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import GetPost from '~/graphql/queries/GetPostByID.gql'

const route = useRoute()
const router = useRouter()

const showReaderSuggestion = ref(false)
const showSocialBookmarks = ref(false)

const { data } = useAsyncQuery<PostResponse>(GetPost, {
  postId: Number.parseInt(route.params.id as string),
})

const { addSlotsToContent } = useAd()

const post = computed(() => {
  if (data.value?.post) {
    return addSlotsToContent(data.value.post)
  }

  return null
})

const userHasAccess = computed(() => useAuth().getUser()?.piano?.abo?.hasPlusAccess ?? false)

const bottomInteractionStats = computed(() => {
  const stats: InteractionItem[] = [
    {
      type: 'home',
      title: 'Zur Startseite',
      disabled: false,
    },
    {
      type: 'tipp',
      title: 'Tipp geben',
      disabled: false,
    },
  ]

  if (post.value?.isVplus) {
    stats.push({
      type: 'gift',
      title: 'Verschenken',
      disabled: !userHasAccess.value,
    })
  }

  stats.push({
    type: 'share',
    title: 'Teilen',
    disabled: false,
  })

  return stats
})

function onInteractionClicked(interaction: InteractionItem) {
  if (interaction.type === 'home') {
    router.push('/')
  }

  if (interaction.type === 'gift') {
    useGiftArticle().showModal()
  }

  if (interaction.type === 'tipp') {
    showReaderSuggestion.value = !showReaderSuggestion.value
  }

  if (interaction.type === 'share') {
    showSocialBookmarks.value = !showSocialBookmarks.value
  }
}

function closeSocialBookmarks() {
  showSocialBookmarks.value = false
}
</script>
