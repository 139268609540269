<template>
  <div v-if="stats.length" class="interaction-bar">
    <div class="interaction-bar__buttons">
      <InteractionButton
        v-for="interaction in stats"
        :key="interaction.type"
        :interaction="interaction"
        @click="onInteractionClicked(interaction)"
      />
    </div>
    <AudioWrapper
      v-if="showAudioPlayer"
      :source="audio?.source ?? ''"
      :post-id="audio?.postId"
      :autoplay="audio?.autoplay ?? false"
      v-bind="$attrs"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import AudioWrapper from '../AudioPlayer/AudioWrapper.vue'
import InteractionButton from './InteractionButton.vue'

const props = withDefaults(
  defineProps<{
    stats?: InteractionItem[]
    audio?: any
  }>(),
  {
    stats: () => [
      {
        type: 'comment-count',
        title: '0',
        disabled: false,
      },
      {
        type: 'text-to-speech',
        title: 'Vorlesen',
        disabled: false,
      },
    ],
    audio: () => ({}),
  },
)

const emit = defineEmits<{
  (event: 'interactionClicked', interaction: any): void
}>()

const showAudioPlayer = ref(false)

function navigateToComments() {
  const urlParams = new URLSearchParams(window.location.search)
  if (!urlParams.has('noheader')) {
    location.hash = 'comments'
  }
}

function onInteractionClicked(interaction: InteractionItem) {
  if (interaction.type === 'comment-count') {
    navigateToComments()
  }

  if (interaction.type === 'text-to-speech' && props.audio.postId) {
    showAudioPlayer.value = !showAudioPlayer.value
  }

  emit('interactionClicked', interaction)
}
</script>

<style>
.interaction-bar {
  @apply flex flex-wrap flex-col justify-center gap-2;

  &__buttons {
    @apply flex flex-wrap justify-evenly gap-x-5 gap-y-4;
  }
}
</style>
