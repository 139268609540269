<template>
  <div class="range-slider__wrapper" :class="additionalClass">
    <input
      type="range"
      class="range-slider"
      data-test-id="range-slider"
      :min="min"
      :max="max"
      :step="step"
      :value="current"
      @change="setValue"
    />

    <div class="range-slider__track">
      <div
        class="range-slider__track--inner"
        :class="{ 'range-slider__track--inner-full': currentWidth >= 99 }"
        :style="`width:${currentWidth}%;`"
      />
    </div>
    <div
      class="range-slider__thumb"
      :style="`transform: translateX(-${
        ((current - min) * 100) / (max - min)
      }%); left:${((current - min) * 100) / (max - min)}%;`"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    min?: number
    max?: number
    step?: number
    current?: number
    additionalClass?: string
  }>(),
  {
    min: 0,
    max: 100,
    step: 1,
    current: 0,
    additionalClass: '',
  },
)

const emit = defineEmits<{
  (event: 'set', value: number | string): void
}>()

const currentWidth = computed(() => {
  return ((props.current - props.min) * 100) / (props.max - props.min)
})

if (props.current > props.max) {
  emit('set', props.max)
}

if (props.current < props.min) {
  emit('set', props.min)
}

function setValue(event: Event) {
  const target = event.target as HTMLInputElement

  emit('set', target.value)
}
</script>

<style>
.range-slider {
  @apply absolute h-full w-full cursor-pointer opacity-0;

  &__wrapper {
    @apply relative h-full;

    &:hover {
      .range-slider__thumb {
        @apply block;
      }
    }
  }

  &__track {
    @apply absolute w-full top-[44%] pointer-events-none bg-black bg-opacity-10 h-[12%] rounded-lg;

    &--inner {
      @apply absolute w-0 h-full bg-black rounded-l-lg;

      &-full {
        @apply rounded-lg;
      }
    }
  }

  &__thumb {
    @apply absolute top-[32%] pointer-events-none rounded-lg w-[11px] h-[34%] bg-black hidden;
  }

  &:hover {
    .range-slider__thumb {
      @apply block;
    }
  }
}
</style>
