<template>
  <div
    id="comments"
    ref="forum"
    class="min-h-32"
  >
    <LazyNPForum
      v-if="commentData"
      :post-id="post.id"
      :session-cookie="authCookies.session"
      :evo-s-s-o-cookie="authCookies.evo"
      :user-blocked="false"
      :comment-data="commentData"
    />
  </div>
</template>

<script setup lang="ts">
import GetComments from '~/graphql/queries/GetComments.gql'

const props = defineProps<{
  post: Post
}>()

const forum = useTemplateRef('forum')
const commentData = ref<any | null>(null)
const authCookies = computed(() => useAuth().getCookies())

const { load: loadComments, onResult } = useLazyQuery(GetComments, {
  postId: props.post.id,
})

onResult((result) => {
  if (result.loading || !result.data) {
    return
  }

  commentData.value = {
    status: result.data.comment?.status ?? '',
    comments: result.data.comment?.comments ?? [],
    paging: {
      total: result.data.comment?.paging?.total ?? 0,
      maxPages: result.data.comment?.paging?.maxPages ?? 0,
    },
    forumEnabled: result.data.comment?.forumEnabled ?? true,
  }
})

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        observer.disconnect()
        loadComments()
      }
    })
  }, {
    rootMargin: '200px',
  })

  if (forum.value) {
    observer.observe(forum.value)
  }
})
</script>
